@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src:
    local('Satoshi-Light'),
    local('manrope Light'),
    url('../../assets/fonts/satoshi/Satoshi-Light.woff')
      format('woff');
}

@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src:
    local('Satoshi-Regular'),
    url('../../assets/fonts/satoshi/Satoshi-Regular.woff')
      format('woff');
}

@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src:
    local('Satoshi-Medium'),
    url('../../assets/fonts/satoshi/Satoshi-Medium.woff')
      format('woff');
}

@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src:
    local('Satoshi-Bold'),
    url('../../assets/fonts/satoshi/Satoshi-Bold.woff')
      format('woff');
}

@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
  src:
    local('Satoshi-Black'),
    url('../../assets/fonts/satoshi/Satoshi-Black.woff')
      format('woff');
}
